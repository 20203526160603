<template>
  <div id="app">
    <!-- <div class="splash-screen" v-if="showSplashScreen">
      <SplashScreen />
    </div>
    <div v-else> -->
    <router-view />
    <!-- <MyPage v-show="isShowComponent" @button-close="closeAside"></MyPage> -->
    <!-- <child :label="buttonLabel" @button-clicked="showMypage"></child> -->
    <!-- </div> -->
  </div>
</template>
<script>
// import SplashScreen from '@/views/main/splash.vue';
// import MyPage from "@/views/mypage/mypage-no-pet.vue"
// import Child from "./components/homeFooter.vue";

export default {
  name: "App",
  components: {
    // SplashScreen,
    // MyPage,
    // Child
  },
  data() {
    return {
      isShowComponent: false,
      showSplashScreen: true,
      buttonLabel: "마이",
      lastErrorTime: Date.now(),
    };
  },
  mounted() {
    // 재 배포시 청크 로드 오류 처리
    this.$router.onError((error) => {
      if (error.name === 'ChunkLoadError') {
        const currentTime = Date.now();
        if(currentTime - this.lastErrorTime >= 3000) {
          this.lastErrorTime = currentTime;
          window.location.reload(true);
        }
      }
    });
  },
  methods: {
    showMypage() {
      // isShowComponent 값을 true로 변경하여 MyComponent를 표시합니다.
      this.isShowComponent = !this.isShowComponent;
    },
    closeAside() {
      this.isShowComponent = false;
    },
  },
  created() {
    // 스플래시 화면 보이기
    setTimeout(() => {
      this.showSplashScreen = false;
    }, 3000);
  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.nodrag {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>
