import ko from '@/i18n/ko.json'
import ko_terms from '@/i18n/ko_terms.json'

import cn from '@/i18n/cn.json'
import cn_terms from '@/i18n/cn_terms.json'

import jp from '@/i18n/jp.json'
import jp_terms from '@/i18n/jp_terms.json'

export default {
    'KO' : { ...ko, ...ko_terms },
    'CN' : { ...cn, ...cn_terms },
    'JP' : { ...jp, ...jp_terms },
}